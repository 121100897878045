import * as React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import joinClasses from '/src/functions/joinClasses'
import './styles.scss'

const Image = loadable(() => import('/src/components/Media/Image'))
const Section = loadable(() => import('/src/components/Structure/Section/Section'))

const ImageStrip = ({ backgroundColour, size, titleArea, imageList, className }) => {
    const classes = ['c-image-strip', ...(className ? [className] : [])]

    const concatenatedClasses = joinClasses(classes)

    return (
        <Section variant={backgroundColour} size={size} titleArea={titleArea} className={concatenatedClasses}>
            <div className="c-image-strip__inner">
                {imageList.map((node, index) => {
                    return <Image key={index} data={node.image} className={'c-image-strip__item'} />
                })}
            </div>
        </Section>
    )
}

ImageStrip.propTypes = {
    backgroundColour: PropTypes.oneOf(['light', 'alt', 'dark', 'gradient', 'other']).isRequired,
    /**
     * Optional alternate component section size
     */
    size: PropTypes.oneOf(['reg', 'sm', 'ty']),
    titleArea: PropTypes.shape({
        addTitleArea: PropTypes.bool,
        title: PropTypes.string
    }),
    imageList: PropTypes.arrayOf(
        PropTypes.shape({
            image: PropTypes.object.isRequired
        })
    ).isRequired,
    /**
     * Optional CSS classes
     */
    className: PropTypes.string
}

ImageStrip.defaultProps = {
    backgroundColour: 'light',
    size: 'ty',
    titleArea: {
        addTitleArea: false
    },
    imageList: [{}, {}, {}, {}, {}]
}

export default ImageStrip

export const query = graphql`
    fragment ImageStripForPageBuilder on WpPage_Acfpagebuilder_Layouts_ImageStrip {
        fieldGroupName
        imageList {
            image {
                id
                localFile {
                    childImageSharp {
                        gatsbyImageData(width: 100)
                    }
                }
                altText
                title
            }
        }
    }
`
